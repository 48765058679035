














































import SKPosition from '@/components/SK-Position.vue';
import { IPosition } from '@/utils/interfaces';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKPosition
  }
})
export default class CompletedPositions extends Vue {
  get completed(): string[] {
    return this.$store.getters.user.completedPositions;
  }

  get positions(): IPosition[] {
    return this.$store.getters.positions;
  }

  get completedPositions(): IPosition[] {
    return this.positions.filter(x => this.completed.includes(x._id));
  }
}
